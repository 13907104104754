import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/compress/Index.vue'

Vue.use(VueRouter)
// const BaseConvertSupportType = '.png,.jpg,.jpeg,.bmp,.gif,.webp'
const BASE_KD = {
  keywords: '图片压缩,压缩图片,图片压缩工具,图片压缩软件',
  description:
    '轻秒图片转换器工具是一个在线图片压缩网站，支持JPG压缩、PNG压缩、GIF压缩、BMP压缩等在线压缩功能，并且能够选择压缩大小的范围。支持各类图片格式互转。'
}
const BASE_LIMIT = 12
const BASE_LIMIT_PHOTO = 1
const LAOZHAOPIAN = 1
const routes = [
  {
    path: '/',
    name: 'compress',
    meta: {
      menuCategory: 'imageCompress',
      accept: '.png,.jpg,.jpeg,.bmp,.webp',
      limit: BASE_LIMIT,
      pageTitle: '图片压缩',
      tdk: {
        title: '轻秒图片转换器_图片格式在线转换_轻秒图片压缩_AI老照片修复_轻秒格式工厂',
        ...BASE_KD
      }
    },
    component: Home
  },
  {
    path: '/compress',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        redirect: () => '/'
      },
      {
        path: 'jpg',
        name: 'compress-jpg',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.jpg',
          limit: BASE_LIMIT,
          pageTitle: 'JPG压缩',
          tdk: {
            title: 'JPG压缩-JPG图片压缩-JPG文件在线压缩-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      {
        path: 'bmp',
        name: 'compress-bmp',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.bmp',
          limit: BASE_LIMIT,
          pageTitle: 'BMP压缩',
          tdk: {
            title: 'BMP压缩-BMP图片压缩-BMP文件在线压缩-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      // {
      //   path: 'gif',
      //   name: 'compress-gif',
      //   meta: {
      //     menuCategory: 'imageCompress',
      //     accept: '.gif',
      //     limit: BASE_LIMIT,
      //     pageTitle: 'GIF压缩',
      //     tdk: {
      //       title: 'GIF压缩-GIF图片压缩-GIF文件在线压缩-轻秒图片转换器',
      //       ...BASE_KD
      //     }
      //   },
      //   component: () => import('@/views/compress/Index.vue')
      // },
      {
        path: 'png',
        name: 'compress-png',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.png',
          limit: BASE_LIMIT,
          pageTitle: 'PNG压缩',
          tdk: {
            title: 'PNG压缩-PNG图片压缩-PNG文件在线压缩-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      {
        path: 'webp',
        name: 'compress-webp',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.webp',
          limit: BASE_LIMIT,
          pageTitle: 'WEBP压缩',
          tdk: {
            title: 'WEBP压缩-WEBP图片压缩-WEBP文件在线压缩-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      {
        path: 'size',
        name: 'compress-size',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: BASE_LIMIT,
          pageTitle: '图片压缩指定大小',
          tdk: {
            title: '图片压缩指定大小_图片压缩大小至指定kb一下-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      {
        path: 'zjz',
        name: 'compress-zjz',
        meta: {
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: BASE_LIMIT,
          pageTitle: '证件照压缩',
          tdk: {
            title: '证件照压缩_证件照怎么压缩到200kb一下-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      {
        path: 'svg',
        name: 'compress-svg',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.svg',
          limit: BASE_LIMIT,
          pageTitle: 'SVG压缩',
          tdk: {
            title: 'SVG压缩-SVG图片压缩-SVG文件在线压缩-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
      {
        path: 'avif',
        name: 'compress-avif',
        meta: {
          menuCategory: 'imageCompress',
          accept: '.avif',
          limit: BASE_LIMIT,
          pageTitle: 'AVIF压缩',
          tdk: {
            title: 'AVIF压缩-AVIF图片压缩-AVIF文件在线压缩-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/compress/Index.vue')
      },
    ]
  },
  {
    path: '/edit',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        name: 'edit',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'edit',
          accept: '.jpg,.jpeg,.png,.bmp',
          limit: BASE_LIMIT,
          pageTitle: '图片改尺寸',
          tdk: {
            title: '在线调整图片大小尺寸工具-在线该图片大小-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'fangda',
        name: 'fangda',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'losslessAmplification',
          accept: '.jpg,.jpeg,.png,.webp,.bmp',
          limit: BASE_LIMIT_PHOTO,
          pageTitle: '图片无损放大',
          tdk: {
            title: '轻秒图片转换器_AI图片无损放大_在线修复清晰度_免费修复模糊照片',
            keywords: '图片无损放大,图片修复度修复,修复模糊照片,轻秒图片转换器',
            description: '轻秒图片转换器在线批量无损放大图像，尺寸放大2倍、4倍、8倍；AI修复图片清晰度，一键修复模糊照片，优化图像纹理细节，智能快速去噪。'
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'hd',
        name: 'clearness-amplification',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'clearnessAamplification',
          accept: '.jpg,.jpeg,.png,.webp,.bmp',
          limit: BASE_LIMIT_PHOTO,
          pageTitle: '图片变清晰',
          tdk: {
            title: 'AI图片修复清晰度_一键修复模糊照片_照片模糊怎么变清晰 - 轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'laozhaopian',
        name: 'laozhaopian',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'laozhaopian',
          accept: '.jpg,.jpeg,.bmp,.png,.tiff,.bitmap',
          limit: LAOZHAOPIAN,
          pageTitle: '老照片修复',
          tdk: {
            title: '老照片修复_怎么修复老旧照片_在线AI修复老照片-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'upcolor',
        name: 'upcolor',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'upcolor',
          accept: '.jpg,.jpeg,.bmp,.png,.webp,.tiff,.bitmap',
          limit: LAOZHAOPIAN,
          pageTitle: '黑白照上色',
          tdk: {
            title: '黑白照片上色_黑白怎么上色_在线AI上色黑白照片-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'dpi',
        name: 'edit-dpi',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'changeDpi',
          accept: '.jpg,.jpeg,.png',
          limit: BASE_LIMIT,
          pageTitle: '修改DPI',
          tdk: {
            ...BASE_KD,
            title: '在线修改照片分辨率（DPI）-图片分辨率修改工具-轻秒图片转换器'
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'increase-memory',
        name: 'increase-memory',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'increaseMemory',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: BASE_LIMIT,
          pageTitle: '内存变大',
          tdk: {
            ...BASE_KD,
            title: '图片内存怎么调大_怎么可以让照片内存变大-轻秒图片转换器'
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'size',
        name: 'edit-size',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'editSize',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: 1,
          pageTitle: '图片编辑',
          tdk: {
            ...BASE_KD,
            title: '在线编辑图片工具_图片尺寸处理工具_轻秒'
          }
        },
        component: () => import('@/views/edit/Edit.vue')
      },
      {
        path: 'radius',
        name: 'edit-radius',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'editRadius',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: 12,
          pageTitle: '图片圆角',
          tdk: {
            title: '图片圆角-在线批量添加圆角或方图圆图',
            ...BASE_KD
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'flip',
        name: 'edit-flip',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'editFlip',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: 12,
          pageTitle: '图片翻转',
          tdk: {
            ...BASE_KD,
            title: '图片翻转_图片镜像翻转_水平翻转_垂直翻转-轻秒',
            description: '轻秒图片翻转在线批量处理图片垂直翻转、水平翻转，不受图片大小或数量限制。'
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'rotate',
        name: 'edit-rotate',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'editRotate',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: 12,
          pageTitle: '图片旋转',
          tdk: {
            ...BASE_KD,
            title: '图片旋转_图片怎么旋转任意角度_图片怎么旋转方向-轻秒',
            description: '轻秒图片旋转在线批量处理图片旋转90°，不受图片大小或数量限制，可轻松调整至任意角度。'
          }
        },
        component: () => import('@/views/edit/Index.vue')
      },
      {
        path: 'join',
        name: 'edit-join',
        meta: {
          menuCategory: 'imageEdit',
          handlingMethod: 'editJoin',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: 100,
          pageTitle: '图片拼接',
          tdk: {
            ...BASE_KD,
            title: '批量多图拼接_图片合成一张图-轻秒',
            description:
              '轻秒图片拼接在线轻松完成图片拼接，多张图片合成一张图片，一次性可拼接100张图片，几秒内即可生成横版或竖版图片。'
          }
        },
        component: () => import('@/views/edit/Join.vue')
      }
    ]
  },
  {
    path: '/convert',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        name: 'convert',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          convertType: 'auto',
          limit: BASE_LIMIT,
          pageTitle: '图片格式转换',
          tdk: {
            title: '在线图片格式转换器-免费转换图片格式-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: '2jpg',
        name: 'convert2jpg',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.png,.bmp,.webp',
          limit: BASE_LIMIT,
          convertType: 'jpg',
          pageTitle: '图片转JPG',
          tdk: {
            title: '图片转JPG-在线图片格式转换成JPG-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: '2bmp',
        name: 'convert2bmp',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.jpg,.jpeg,.png,.webp',
          limit: BASE_LIMIT,
          convertType: 'bmp',
          pageTitle: '图片转BMP',
          tdk: {
            title: '图片转BMP-在线图片格式转换成BMP-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: '2ico',
        name: 'convert2ico',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.jpg,.jpeg,.png,.bmp,.webp',
          limit: BASE_LIMIT,
          convertType: 'ico',
          pageTitle: '图片转ICO',
          tdk: {
            title: 'JPG、PNG、BMP转ICON-在线将图片转换成ICON格式-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: '2png',
        name: 'convert2png',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.jpg,.jpeg,.bmp,.webp',
          limit: BASE_LIMIT,
          convertType: 'png',
          pageTitle: '图片转PNG',
          tdk: {
            title: '图片转PNG-在线图片格式转换成PNG-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: '2webp',
        name: 'convert2webp',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.jpg,.jpeg,.png,.bmp',
          limit: BASE_LIMIT,
          convertType: 'webp',
          pageTitle: '图片转WEBP',
          tdk: {
            title: '图片转WEBP-在线图片格式转换成WEBP-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: 'heic2jpg',
        name: 'heic2jpg',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.heic',
          limit: BASE_LIMIT,
          convertType: 'jpg',
          pageTitle: 'HEIC转JPG',
          tdk: {
            title: 'HEIC转JPG-在线HEIC格式转换成JPG-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      },
      {
        path: 'tiff2jpg',
        name: 'tiff2jpg',
        meta: {
          menuCategory: 'imageConvert',
          accept: '.tiff',
          limit: BASE_LIMIT,
          convertType: 'jpg',
          pageTitle: 'TIFF转JPG',
          tdk: {
            title: 'TIFF转JPG-在线TIFF格式转换成JPG-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/convert/Index.vue')
      }
    ]
  },
  {
    path: '/pdf',
    component: () => import('@/App.vue'),
    children: [
      {
        path: 'compress',
        name: 'pdf_compress',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.pdf',
          allowedType: ['application/pdf'],
          convertType: 'pdf',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'PDF压缩',
          tdk: {
            title: 'PDF压缩_PDF怎么在线压缩大小-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'to-word',
        name: 'pdf2word',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.pdf',
          allowedType: ['application/pdf'],
          convertType: 'doc',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'PDF转Word',
          tdk: {
            title: 'PDF转Word_PDF怎么转换成Word-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'to-img',
        name: 'pdf2img',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.pdf',
          allowedType: ['application/pdf'],
          convertType: 'jpg',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'PDF转图片',
          tdk: {
            title: 'PDF转图片_PDF怎么转换成JPG图片-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'to-ppt',
        name: 'pdf2ppt',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.pdf',
          allowedType: ['application/pdf'],
          convertType: 'pptx',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'PDF转PPT',
          tdk: {
            title: 'PDF转PPT_怎么把PDF转换成PPT-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'to-excel',
        name: 'pdf2excel',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.pdf',
          allowedType: ['application/pdf'],
          convertType: 'xls',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'PDF转Excel',
          tdk: {
            title: 'PDF转Excel_PDF转换成Excel-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'word2pdf',
        name: 'word2pdf',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.doc,.docx',
          allowedType: [
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/wps-writer'
          ],
          convertType: 'pdf',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'Word转PDF',
          tdk: {
            title: 'Word转PDF_Word怎么转PDF-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'img2pdf',
        name: 'img2pdf',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.jpg,.jpeg,.png',
          allowedType: ['image/jpeg', 'image/png'],
          convertType: 'pdf',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: '图片转PDF',
          tdk: {
            title: 'JPG转PDF_图片转PDF_JPG怎么转换成PDF-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'ppt2pdf',
        name: 'ppt2pdf',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.ppt,.pptx',
          allowedType: [
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation'
          ],
          convertType: 'pdf',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'PPT转PDF',
          tdk: {
            title: 'PPT转PDF_PPT怎么转换成PDF-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'excel2pdf',
        name: 'excel2pdf',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.xls,.xlsx',
          allowedType: [
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          ],
          convertType: 'pdf',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'Excel转PDF',
          tdk: {
            title: 'Excel转PDF_Excel怎么转PDF-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      },
      {
        path: 'cad2pdf',
        name: 'cad2pdf',
        meta: {
          menuCategory: 'pdfConvert',
          accept: '.dwg,.dxf,.dwt,.dws',
          allowedType: [
            'application/acad',
            'application/x-acad',
            'application/dxf',
            'application/x-dxf',
            'application/x-dwt',
            'application/x-dws',
            'application/octet-stream'
          ],
          convertType: 'pdf',
          limit: 1,
          size: 200 * 1024 * 1024,
          pageTitle: 'CAD转PDF',
          tdk: {
            title: 'CAD转PDF_CAD怎么转换成PDF格式-轻秒图片转换器PDF转换在线',
            ...BASE_KD
          }
        },
        component: () => import('@/views/pdf/index.vue')
      }
    ]
  },
  {
    path: '/about',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        name: 'About',
        meta: {
          tdk: {
            title: '',
            keywords: '',
            description: ''
          }
        },
        component: () => import('@/views/about/index.vue')
      }
    ]
  },
  {
    path: '/gif',
    component: () => import('@/App.vue'),
    children: [
      {
        path: 'compress',
        name: 'GifCompress',
        meta: {
          accept: '.gif',
          limit: BASE_LIMIT,
          pageTitle: 'GIF压缩',
          tdk: {
            title: 'GIF压缩_动图压缩_GIF图片在线压缩_GIF图片压缩工具-轻秒图片转换器',
            ...BASE_KD
          }
        },
        component: () => import('@/views/gif/compress.vue')
      },
      {
        path: 'crop',
        name: 'GifCrop',
        meta: {
          accept: '.gif',
          limit: 1,
          pageTitle: 'GIF裁剪',
          tdk: {
            title: 'GIF裁剪_GIF图片裁剪_GIF图片在线裁剪软件',
            ...BASE_KD
          }
        },
        component: () => import('@/views/gif/crop.vue')
      },
      {
        path: 'scale',
        name: 'GifScale',
        meta: {
          accept: '.gif',
          limit: 1,
          pageTitle: 'GIF缩放',
          tdk: {
            title: 'GIF缩放_GIF图片放大_GIF图片缩小_GIF图片尺寸修改',
            ...BASE_KD
          }
        },
        component: () => import('@/views/gif/scale.vue')
      }
    ]
  },
  {
    path: '/account',
    component: () => import('@/App.vue'),
    children: [
      {
        path: '',
        redirect: () => '/account/setting'
      },
      {
        path: 'setting',
        name: 'AccountSetting',
        component: () => import('@/views/account/setting.vue')
      }
    ]

  }
  // {
  //   path: '*',
  //   name: 'NotFound',
  //   redirect: () => '/'
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
      behavior: 'smooth'
    }
  }
})

export default router
