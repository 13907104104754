<template>
  <DefaultLayout
    :steps-area-title="stepsAreaTitle"
    :steps="steps"
    :features-area-title="featuresAreaTitle"
    :features="features"
  >
    <div class="uploadAreaWrapper">
      <div class="uploadAreaContainer">
        <div class="uploadAreaTitle">{{ pageTitle }}</div>
        <div class="homePageContainer">
          <AsideNav :menus="menus" class="asideNav" />
          <UploadFile
            :accept="accept"
            :limit="limit"
            @onExceed="onUploadExceed"
            @onSuccess="onUploadSuccess"
            v-if="files.length === 0"
            class="uploadFileComponent"
          />
          <FileList
            v-else
            :files="files"
            :accept="accept"
            :limit="limit"
            @onExceed="onUploadExceed"
            @onSuccess="onUploadSuccess"
            @onReUpload="handleReUpload"
            @onResetData="resetData"
            class="fileListComponent"
          >
            <div class="paramsSettingBox">
              <div
                class="paramItem"
                v-if="
                  $route.name !== 'compress-png' && $route.name !== 'compress-svg' && $route.name !== 'compress-avif'
                "
              >
                <span class="label">压缩设置：</span>
                <el-radio-group v-model="checkSetting" class="groups">
                  <el-radio v-if="$route.name !== 'compress-size'" label="minimize">缩小优先</el-radio>
                  <el-radio v-if="$route.name !== 'compress-size'" label="clear">清晰优先</el-radio>
                  <div v-if="$route.name !== 'compress-size'" class="clearRadioBox">
                    <el-radio label="clearValue" class="clearRadio">指定清晰度</el-radio>
                    <el-slider v-model="clearValue" class="slider" @change="handleFocusClearValue" />
                    <el-input-number
                      v-model="clearValue"
                      :controls="false"
                      :min="0"
                      class="inputNumber"
                      @focus="handleFocusClearValue"
                    />
                    %
                  </div>
                  <el-radio label="size">
                    指定大小
                    <el-tooltip content="实际结果和希望值会存在一定偏差" placement="top">
                      <el-input-number
                        v-model="size"
                        :controls="false"
                        :min="0"
                        class="inputNumber"
                        @focus="handleInputSize"
                      />
                    </el-tooltip>
                    KB
                  </el-radio>
                </el-radio-group>
              </div>
              <div style="display: flex; align-items: center" v-else-if="$route.name === 'compress-png'">
                <div class="paramItem" style="margin-right: 20px">
                  <span class="label" style="display: flex; align-items: center"
                    ><el-tooltip content="数值越小，压缩后图片越小"
                      ><img
                        src="@/assets/img/help.svg"
                        alt=""
                        style="width: 18px; margin-right: 2px; cursor: pointer" /></el-tooltip
                    >设置输出颜色数量（2-256）:</span
                  >
                  <el-slider
                    v-model="colors"
                    class="slider"
                    :min="2"
                    :max="256"
                    style="width: 180px"
                    @change="handleChangePngParams"
                  />
                </div>
                <div class="paramItem">
                  <span class="label" style="display: flex; align-items: center"
                    ><el-tooltip content="数值越小，压缩后图片越小"
                      ><img
                        src="@/assets/img/help.svg"
                        alt=""
                        style="width: 18px; margin-right: 2px; cursor: pointer" /></el-tooltip
                    >设置抖色系数（0-1）:</span
                  >
                  <el-slider
                    v-model="dithering"
                    class="slider"
                    :min="0"
                    :max="1"
                    :step="0.01"
                    style="width: 180px"
                    @change="handleChangePngParams"
                  />
                </div>
              </div>
              <div style="display: flex; align-items: center" v-else-if="$route.name === 'compress-avif'">
                <div class="paramItem" style="margin-right: 20px">
                  <span class="label" style="display: flex; align-items: center"
                    ><el-tooltip content="数值越小，压缩后图片越小"
                      ><img
                        src="@/assets/img/help.svg"
                        alt=""
                        style="width: 18px; margin-right: 2px; cursor: pointer" /></el-tooltip
                    >设置输出图片质量（1-100）:</span
                  >
                  <el-slider v-model="avifQuality" class="slider" :min="1" :max="100" style="width: 180px" />
                </div>
                <div class="paramItem">
                  <span class="label" style="display: flex; align-items: center"
                    ><el-tooltip content="数值越小，压缩后图片越小"
                      ><img
                        src="@/assets/img/help.svg"
                        alt=""
                        style="width: 18px; margin-right: 2px; cursor: pointer" /></el-tooltip
                    >设置压缩速度（1-10）:</span
                  >
                  <el-slider v-model="avifSpeed" class="slider" :min="1" :max="10" style="width: 180px" />
                </div>
              </div>
              <button class="startBtn" @click="execute">开始压缩</button>
            </div>
          </FileList>
        </div>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import UploadFile from '@/components/UploadFile/index.vue'
import AsideNav from '@/components/AsideNav/index.vue'
import FileList from '@/components/FileList/index.vue'
import { headerMenus } from '../../config/menus.config'
import { COMPRESS_UPLOAD_CONFIG } from '@/config'
import { IMG_COMPRESS_PAGE_CONFIG } from '@/config/page.config'
import cloneDeep from 'lodash-es/cloneDeep'
import { mapActions } from 'vuex'
import { formatTypes } from '../../config'
import { Message } from 'element-ui'

export default {
  name: 'Home',
  metaInfo() {
    return {
      ...this.tdk
    }
  },
  components: {
    FileList,
    AsideNav,
    UploadFile,
    DefaultLayout
  },
  data() {
    return {
      tdk: {},
      accept: '', // 支持的上传格式，例：'.jpg,.webp,...'
      pageTitle: '',
      limit: COMPRESS_UPLOAD_CONFIG.COUNTS, // 最多上传的数量
      menus: [], // 左侧导航栏
      stepsAreaTitle: IMG_COMPRESS_PAGE_CONFIG.STEP_TITLE,
      steps: IMG_COMPRESS_PAGE_CONFIG.STEPS,
      featuresAreaTitle: IMG_COMPRESS_PAGE_CONFIG.FEATURES_TITLE,
      features: IMG_COMPRESS_PAGE_CONFIG.FEATURES,
      isShowBuyTips: false,
      isShowExceedDownloadTips: false,
      files: [], // 文件列表
      checkSetting: 'clear', // 选择的压缩设置
      clearValue: 70, // 指定清晰度的值
      size: 0, // 指定的文件大小
      isProcessed: false, // 是否点击过处理
      dithering: this.getLocalData('p')?.dithering || 0.5,
      colors: this.getLocalData('p')?.colors || 80,
      avifQuality: 40,
      avifSpeed: 8
    }
  },
  methods: {
    ...mapActions(['resetHistoryDownloads']),
    /**
     * @description 路由变化时执行
     * @param route
     * @returns {Promise<void>}
     */
    async onRouterChange(route) {
      /* 判断当前路径，渲染左侧导航 */
      if (this.$route.path === '/' || this.$route.path.indexOf('/compress') != -1) {
        let asideMenus = headerMenus.filter(item => item.key === 'compress')
        if (asideMenus.length !== 0) {
          this.menus = asideMenus[0]?.children
        }
      }
      const { accept, pageTitle, limit, tdk } = route.meta
      this.tdk = {
        title: tdk?.title,
        meta: [
          {
            name: 'keywords',
            content: tdk?.keywords
          },
          {
            name: 'description',
            content: tdk?.description
          }
        ]
      }
      this.accept = accept /* 获取当前支持的上传格式 */
      this.limit = limit
      this.pageTitle = pageTitle
      this.handleReUpload()
    },
    /**
     * @description  获取压缩参数
     * @returns {{maxSize: number, quality: number}}
     */
    getCompressOptions() {
      let quality = 1
      let maxSize = 0
      if (this.checkSetting === 'minimize') {
        maxSize = Number.POSITIVE_INFINITY
        // 缩小优先
        quality = COMPRESS_UPLOAD_CONFIG.COMPRESS_SETTINGS.MINI
      } else if (this.checkSetting === 'clear') {
        maxSize = Number.POSITIVE_INFINITY
        // 清晰度优先
        // quality = COMPRESS_UPLOAD_CONFIG.COMPRESS_SETTINGS.CLEAR
        quality = this.clearValue / 100
      } else if (this.checkSetting === 'clearValue') {
        maxSize = Number.POSITIVE_INFINITY
        // 指定清晰度
        quality = this.clearValue / 100
      } else if (this.checkSetting === 'size') {
        // 指定大小
        maxSize = this.size
        quality = 1
      }
      return {
        quality: quality, // 压缩率
        maxSize: maxSize, // 指定大小
        colors: this.colors,
        dithering: this.dithering,
        avifQuality: this.avifQuality,
        avifSpeed: this.avifSpeed
      }
    },
    // 输入指定大小，选中当前设置
    handleInputSize() {
      this.checkSetting = 'size'
    },
    handleFocusClearValue() {
      this.checkSetting = 'clearValue'
    },

    /**
     * @description 本地上传完成
     * @param file Blob对象
     */
    onUploadSuccess(file) {
      let supportTypes = []
      let suffixArray = this.accept.split(',')
      suffixArray.forEach(item => {
        let format = item.split('.')[1]
        supportTypes.push(formatTypes[format])
      })
      if (!supportTypes.includes(file.type)) {
        Message({
          type: 'error',
          message: '请上传指定的格式！'
        })
        return
      }
      if (file.size < 100 * 1024 * 1024) {
        // 校验文件大小
        const options = this.getCompressOptions()
        this.files.push({
          raw: file,
          options: options
        })
      } else {
        this.$Msg({
          showLeft: false,
          rightBtnTxt: '知道了',
          content: `单个文件大小不得超过100MB`
        })
      }
    },
    /**
     * @description 上传文件超出数量限制
     */
    onUploadExceed() {
      this.$Msg({
        showLeft: false,
        rightBtnTxt: '知道了',
        content: `单次上传文件不得超过${this.limit}个`
      })
    },
    /**
     * @description 重新上传
     */
    handleReUpload() {
      if (this.isProcessed) {
        this.$Msg({
          content: '重新上传前先下载保存当前文件，是否重新上传？',
          rightBtnTxt: '重新上传',
          onClickRightBtn: () => {
            this.resetData()
            this.resetHistoryDownloads() // 重置下载历史
          }
        })
      } else {
        this.resetData()
      }
    },
    resetData() {
      this.checkSetting = this.$route.name === 'compress-size' ? 'size' : 'clear'
      this.clearValue = 70
      this.size = 0
      this.files = []
      this.isProcessed = false
      this.colors = this.getLocalData('p')?.colors || 80
      this.dithering = this.getLocalData('p')?.dithering || 0.5
      this.avifQuality = 40
      this.avifSpeed = 8
    },
    /**
     * @description 开始压缩
     */
    execute() {
      const options = this.getCompressOptions()
      let cloneData = cloneDeep(this.files)
      cloneData.forEach(item => {
        item.options = options
      })
      this.isProcessed = true
      this.files = cloneData
    },
    handleChangePngParams() {
      this.setLocalData('p', {
        colors: this.colors,
        dithering: this.dithering
      })
    },
    setLocalData(key, value) {
      window.localStorage.setItem(key, JSON.stringify(value))
    },
    getLocalData(key) {
      let v = localStorage.getItem(key)
      if (v) {
        return JSON.parse(v)
      } else {
        return ""
      }
    }
  },
  watch: {
    // 监听路由变化
    $route: {
      handler(val) {
        this.onRouterChange(val)
      },
      immediate: true,
      deep: true
    }
  },
  async created() {
    // await this.onRouterChange(this.$route)
  }
}
</script>
<style scoped lang="less">
@import url('./index.less');
</style>
